export const ServicesOptions = [
  {
    title: "Life Systems",
    imgSrc: require("./../../../assets/images/weOffer/fire.jpg"),
    position: "left",
    route: "/services/life-systems",
  },
  {
    title: "Intruder",
    imgSrc: require("./../../../assets/images/weOffer/security.jpg"),
    position: "top",
    route: "/services/intruder",
  },
  {
    title: "Access Control",
    imgSrc: require("./../../../assets/images/weOffer/access.jpg"),
    position: "right",
    route: "/services/access-control",
  },
  {
    title: "IPVS",
    imgSrc: require("./../../../assets/images/weOffer/cctv.jpg"),
    position: "bottom",
    route: "/services/ip-vs",
  },
  {
    title: "PA Systems",
    imgSrc: require("./../../../assets/images/weOffer/pa_systems.jpg"),
    position: "right",
    route: "/services/pa-systems",
  },
  {
    title: "Design Services",
    imgSrc: require("./../../../assets/images/weOffer/design_services.jpg"),
    position: "right",
    route: "/services/design-services",
  },
];

export const ProjectsOptions = [
  {
    title: "Healthcare - Northern Ireland",
    imgSrc: require("./../../../assets/images/caseStudies/Healthcare_Northern_Ireland.jpg"),
    position: "top",
  },
  {
    title: "Central Foundation School - London",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Central_Foundation_Boys%27_School_2.jpg/1024px-Central_Foundation_Boys%27_School_2.jpg",
    position: "bottom",
  },
  {
    title: "Offices - Mid Ulster",
    imgSrc: require("./../../../assets/images/caseStudies/offices_Mid_ulster.jpg"),
    position: "top",
  },
  {
    title: "Pixash Recycling Centre - Bath",
    imgSrc:
      "https://www.bathecho.co.uk/uploads/2022/02/pixash-recycling-site-banes-plans.jpg",
    position: "bottom",
  },
  {
    title: "Leisure Centre - Belfast",
    imgSrc: require("./../../../assets/images/caseStudies/Leisure_centre_belfast.jpg"),
    position: "right",
  },
  {
    title: "Student Accommodation - Bristol",
    imgSrc: require("./../../../assets/images/caseStudies/student_accommodation_Bristol.jpg"),
    position: "right",
  },

  {
    title: "Office Development - Derry",
    imgSrc: require("./../../../assets/images/caseStudies/Office_development_Derry.jpg"),
    position: "left",
  },
  {
    title: "Lambeth Steam College - London",
    imgSrc: "https://pagabo.co.uk/storage/uploads/lambeth-2.jpg",
    position: "bottom",
  },
  {
    title: "Pump Station - Dublin",
    imgSrc: require("./../../../assets/images/caseStudies/Pump_station_dublin.jpg"),
    position: "right",
  },
  {
    title: "Sports Grounds - Belfast",
    imgSrc: require("./../../../assets/images/caseStudies/Sports_grounds_Belfast.jpg"),
    position: "left",
  },
  {
    title: "Orchard House - Derry",
    imgSrc: require("./../../../assets/images/caseStudies/Orchard_House.jpg"),
    position: "bottom",
  },
];
