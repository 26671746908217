export const DropdownOptions = [
  {
    title: "Life Systems",
    route: "/services/life-systems",
  },
  {
    title: "Intruder",
    route: "/services/intruder",
  },
  {
    title: "Access Control",
    route: "/services/access-control",
  },
  {
    title: "PA Systems",
    route: "/services/pa-systems",
  },
  {
    title: "IPVS Systems",
    route: "/services/ip-vs",
  },
  { title: "Design Services", route: "/services/design-services" },
];
