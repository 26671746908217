import styled from "styled-components";

export const StyledLi = styled.li`
  padding: 0.3em 0em;
  border-radius: 0.2em;
  border: none;
  &:hover {
    background-color: #f1f1f1;
  }
`;
