export const Accreditations = [
  {
    id: 1,
    title: "NSI Gold",
    summary:
      "NSI Gold accreditation demonstrates compliance with Security industry standards in Quality Management and is BS EN 9001 compliant. NSI audits demand full compliance with all standards as well as adherence to recommended codes of best practice. Customers can have the utmost confidence in all works performed.",
    image: require("../../assets/images/awards/nsv.jpg"),
    isPortrait: false,
  },
  {
    id: 2,
    title: "Auva",
    summary:
      "Discover the Power of Environmental Responsibility with ISO 14001 Certification. Our environmental management system adheres to the globally recognised ISO 14001 standard, demonstrating our commitment to sustainability and environmental stewardship. Our organisation systematically manages environmental aspects, reduces its ecological footprint, and achieves operational excellence.",
    image: require("../../assets/images/awards/auva.jpg"),
    isPortrait: false,
  },
  {
    id: 3,
    title: "Constructionline Certified",
    summary:
      "Ambar Systems has been a Constructionline member for many years. Accreditation shows that we meet industry health and safety standards as well as simplifying and speeding up the procurement process for our customers. In using the Constructionline network, customers can improve cost effectiveness and resource access.",
    image: require("../../assets/images/awards/constructionLine.jpg"),
    isPortrait: false,
  },
  {
    id: 4,
    title: "Fire Detection",
    summary:
      "SSAIB(Security Systems and Alarms Inspection Board) is a UK-based certification body that provides accreditation for organisations offering security and fire safety services.",
    image: require("../../assets/images/awards/fire-detection.jpg"),
    isPortrait: false,
  },
  {
    id: 5,
    title: "Salto Certified",
    summary:
      "'Gold Partner' demonstrates a high level of expertise, certification, and collaboration with the Salto Systems.",
    image: require("../../assets/images/awards/salto.png"),
    isPortrait: true,
  },
  {
    id: 6,
    title: "BAFE Certified",
    summary:
      "BAFE develops schemes and assessments to help improve and maintain quality and competency within the fire protection industry. These schemes are designed to ensure that companies providing fire protection services meet certain standards and are competent in their respective areas.",
    image: require("../../assets/images/awards/BAFE.jpg"),
    isPortrait: true,
  },
];
