export const ServiceInfo = [
  {
    id: "life-systems",
    title: "Life Systems",
    summary: "Fire Detection and alarm systems",
    description:
      "Ambar Systems understand the importance of protecting your business, staff and visitors from the potentially devastating effects that fire can cause.\nWe provide a full range of service for all types of fire alarm systems ranging from conventional systems to fully addressable confirmed systems with fire brigade response.\nWe provide a fully accredited service and are certified with SSAIB BAFE SP203-1.\nAmbar Systems provide other life safety systems such disabled refuge and toilets alarm systems.\nAmbar Systems install open protocol fire alarm systems. By choosing an open protocol system you have the freedom of not being tied into using a specific manufacturer or company, ensuring you always receive cost effective solutions for any future upgrade works on the system.\nFor further info and quotation please call 028 7963 9446",
    products: [
      {
        id: "fire-systems-product1",
        title: "Product 1 Fire",
        link: "https://www.google.com",
      },
      {
        id: "fire-systems-product2",
        title: "Product 2 Fire",
        link: "https://www.google.com",
      },
      {
        id: "fire-systems-product3",
        title: "Product 3 Fire",
        link: "https://www.google.com",
      },
    ],
    image: {
      src: require("../../assets/images/Products/Life_Systems.png"),
      alt: "Life Systems",
    },
  },
  {
    id: "intruder",
    title: "Intruder",
    summary: "Intruder Systems",
    description:
      "Ambar Systems know that security is of the utmost importance to us all.\nWhether you are looking to ensure your business is fully protected from intrusion, to protect your home and provide peace of mind for your loved ones; we have a range of intruder alarm systems to suit your individual requirements.\nWe provide a full installation, commission, and maintenance service for all types of intruder alarm systems to the latest European Standards.\nWe provide a fully accredited service and are a certified NSI Gold Company.\nWe offer dual path monitored systems with police response using Dualcom and Redcare diallers.\nFor further info and quotation please call 028 7963 9446",
    products: [
      {
        id: "intruder-alarm-product1",
        title: "Product 1",
        link: "https://www.google.com",
      },
      {
        id: "intruder-alarm-product2",
        title: "Product 2",
        link: "https://www.google.com",
      },
      {
        id: "intruder-alarm-product3",
        title: "Product 3",
        link: "https://www.google.com",
      },
    ],
    image: {
      src: require("../../assets/images/Products/Intruder_Systems.png"),
      alt: "Intruder Systems",
    },
  },
  {
    id: "access-control",
    title: "Access Control",
    summary: "Access Control and Door Entry Systems",
    description:
      "An Electronic Access Control System is the best way to control who enters your premises.\nAmbar Systems provides a wide range of Access Control to ensure all our clients' needs are met and exceeded.\nWe offer online and offline solutions and cater for bespoke system requirements.\nWe provide a fully accredited service and are a certified NSI Gold Company.\nFor further info and quotation please call 028 7963 9446",
    products: [
      {
        id: "access-control-product1",
        title: "Product 1",
        link: "https://www.google.com",
      },
      {
        id: "access-control-product2",
        title: "Product 2",
        link: "https://www.google.com",
      },
      {
        id: "access-control-product3",
        title: "Product 3",
        link: "https://www.google.com",
      },
    ],
    image: {
      src: require("../../assets/images/Products/Access_Control.png"),
      alt: "Access Control",
    },
  },
  {
    id: "ip-vs",
    title: "IPVS Systems",
    summary: "CCTV Systems",
    description:
      "CCTV can help protect your property 24/7. Not only does the presence of CCTV cameras act as a deterrent, Ambar Systems offers cameras that can send notifications to the end user using analytics and smart technology to provide video verification at the touch of a button.\nAll our products of choice are IP based and fully ONVIF compliant.\nWe provide a fully accredited service and are a certified NSI Gold Company.\nFor further info and quotation please call 028 7963 9446",
    products: [
      {
        id: "ipcctv-systems-product1",
        title: "Product 1",
        link: "https://www.google.com",
      },
      {
        id: "ipcctv-systems-product2",
        title: "Product 2",
        link: "https://www.google.com",
      },
      {
        id: "ipcctv-systems-product3",
        title: "Product 3",
        link: "https://www.google.com",
      },
    ],
    image: {
      src: require("../../assets/images/Products/IPVS.png"),
      alt: "IPVS Systems",
    },
  },
  {
    id: "pa-systems",
    title: "PA Systems",
    summary: "PA Systems",
    description:
      "Ambar Systems can provide a full suite of public announcement systems.\nRanging from background music to full PAVA (public address and voice alarm).\nFor further info and quotation please call 028 7963 9446",
    products: [
      {
        id: "pa-systems-product1",
        title: "Product 1",
        link: "https://www.google.com",
      },
      {
        id: "pa-systems-product2",
        title: "Product 2",
        link: "https://www.google.com",
      },
      {
        id: "pa-systems-product3",
        title: "Product 3",
        link: "https://www.google.com",
      },
    ],
    image: {
      src: require("../../assets/images/Products/PA_Systems.png"),
      alt: "PA Systems",
    },
  },
  {
    id: "design-services",
    title: "Design Services",
    summary: "Design Services",
    description:
      "At  Ambar systems, we understand the critical importance of effective fire design in safeguarding lives, property, and assets. We take pride in offering comprehensive fire design services tailored to meet the unique needs of our clients.\nFor further info and quotation please call 028 7963 9446",
    image: {
      src: require("../../assets/images/weOffer/design_services.jpg"),
      alt: "Design Services",
    },
  },
];
