import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { DropdownSelector } from "../../components/elements/DropdownSelector";
import { DropdownOptions } from "../../components/elements/Dropdown/options";
import Image from "../../components/elements/Image";
import { ServiceInfo } from "./servicesInfo";
import {
  ColumnContainer,
  NavigationInfoContainer,
  ScreenWrapper,
  StyledContainer,
  StyledHeadline,
  StyledNavigationText,
  StyledSubHeadline,
} from "./styles";
import { useMediaQuery } from "../../hooks/MediaHook";

export const Services = () => {
  const paragraphText = (text) =>
    text.split("\n").map((str) => (
      <p
        style={{
          textAlign: isMobile ? "center" : "left",
        }}
      >
        {str}
      </p>
    ));
  const serviceId = useParams().id;
  const [service, setService] = React.useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 1200px)");
  console.log(service);

  React.useEffect(() => {
    const serviceInfo = ServiceInfo.find(
      (tempService) => tempService.id === serviceId
    );
    setService(serviceInfo);
  }, [serviceId]);

  React.useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <>
      <ColumnContainer isMobile={isMobile}>
        {!isMobile && (
          <StyledHeadline isMobile={isMobile}>{service?.title}</StyledHeadline>
        )}
        <NavigationInfoContainer>
          {!isMobile && (
            <>
              <StyledNavigationText>Home</StyledNavigationText>
              <StyledNavigationText>{">"}</StyledNavigationText>
              <StyledNavigationText>Services</StyledNavigationText>
              <StyledNavigationText>{">"}</StyledNavigationText>
            </>
          )}
          <div>
            <DropdownSelector
              title={service?.title}
              options={DropdownOptions}
            />
          </div>
        </NavigationInfoContainer>
      </ColumnContainer>
      <ScreenWrapper isMobile={isMobile}>
        <StyledContainer isMobile={isMobile}>
          {service?.image && (
            <Image
              src={service?.image.src}
              alt={service?.image.alt}
              style={{
                alignSelf: "center",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              }}
            />
          )}

          <div>
            <StyledSubHeadline isMobile={isMobile}>
              {service?.summary}
            </StyledSubHeadline>
            {service?.description && paragraphText(service?.description)}
          </div>
        </StyledContainer>
      </ScreenWrapper>
    </>
  );
};
