import styled from "styled-components";

export const HeroContainer = styled.div`
  padding: 2em 0em 0em 6em;
  display: flex;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 4em;
    padding: 2em 2em 2em 2em;
    align-items: center;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 4em;
    padding: 0em 1em 1em 1em;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 2em;
  gap: 1em;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const HeroTitle = styled.h2`
  color: #fff;
  text-align: left;

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const HeroText = styled.p`
  text-align: left;

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const LottieContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: -40px;
`;
