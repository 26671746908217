import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import Button from "../../elements/Button";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import arrowAnimationData from "../../../assets/images/lottie/down_arrow.json";
import {
  ButtonContainer,
  HeroContainer,
  HeroText,
  HeroTitle,
  LottieContainer,
} from "./style";
import { useMediaQuery } from "../../../hooks/MediaHook";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({ topDivider, bottomDivider }) => {
  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 400px)");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "80vh",
        width: "100vw",
        zIndex: 2,
        marginTop: "2em",
      }}
    >
      <HeroContainer className={innerClasses}>
        <HeroTitle
          className="m-0 mb-32 reveal-from-bottom"
          data-reveal-delay="300"
        >
          Fire and Security System Specialists
        </HeroTitle>
        <HeroText
          className="m-0 mb-32 reveal-from-bottom"
          data-reveal-delay="300"
        >
          Empower your safety with our elite team of seasoned professionals.
          With a proven history of excellence, we specialize in delivering
          advanced fire and security solutions — from cutting-edge designs to
          seamless project handovers, your protection is our priority.
        </HeroText>
        <ButtonContainer className="reveal-from-bottom" data-reveal-delay="350">
          <Button
            tag="a"
            color="primary"
            wideMobile={false}
            size={isMobile ? "sm" : "lg"}
            onClick={() => {
              history.push("/contact-us");
            }}
          >
            Get in contact
          </Button>
          <Button
            tag="a"
            color="dark"
            size={isMobile ? "sm" : "lg"}
            wideMobile={false}
            onClick={() => {
              history.push("/projects");
            }}
          >
            Our Projects
          </Button>
        </ButtonContainer>
      </HeroContainer>
      {!isMobile && (
        <LottieContainer onClick={() => history.push("/services")}>
          <Lottie
            options={defaultOptions}
            height={130}
            width={130}
            style={{ cursor: "pointer" }}
          />
        </LottieContainer>
      )}
    </div>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;

export const styles = {
  headlineText: {
    fontSize: "4rem",
    fontWeight: "bold",
    color: "white",
    textShadow: "0px 0px 10px black",
    marginBottom: "1em",
  },
};
