import React from "react";
import "image-overlay-react/dist/index.css";
import { useHistory } from "react-router-dom";
import {
  FloatingTextContainer,
  FloatingTextContainer2,
  ImageGrid,
  StyledImage,
  StyledImageWithText,
  StyledImageWithTextWithoutHover,
  StyledTitle,
  UniformGrid,
} from "./style";
import { useMediaQuery } from "../../../hooks/MediaHook";

export const Gallery = ({ imageSet, uniform = true, withOverlay = true }) => {
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const history = useHistory();
  const getImages = () =>
    imageSet.map((item) => (
      <>
        {!withOverlay && (
          <StyledImageWithTextWithoutHover>
            <StyledImage src={item.imgSrc} alt="" />
            <FloatingTextContainer2>
              <StyledTitle isMobile={isMobile}>{item.title}</StyledTitle>
            </FloatingTextContainer2>
          </StyledImageWithTextWithoutHover>
        )}
        {withOverlay && (
          <StyledImageWithText>
            <StyledImage src={item.imgSrc} alt="" />
            <FloatingTextContainer onClick={() => history.push(item.route)}>
              <StyledTitle>{item.title}</StyledTitle>
            </FloatingTextContainer>
          </StyledImageWithText>
        )}
      </>
    ));

  return (
    <>
      {uniform ? (
        <UniformGrid itemSize={isMobile ? "75px" : "150px"}>
          {getImages()}
        </UniformGrid>
      ) : (
        <ImageGrid isMobile={isMobile}>{getImages()}</ImageGrid>
      )}
    </>
  );
};
