import styled from "styled-components";

export const BlackOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0em;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  z-index: 1;
`;

export const FloatingTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0em;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`;

export const FloatingText = styled.h2`
  position: absolute;
  bottom: 7%;
  right: 5%;
  color: #979a9a;
  font-size: 1.5em;
  font-weight: 400;
  z-index: 10;

  @media (max-width: 1200px) {
    font-size: 1em;
  }
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;
