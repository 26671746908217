import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const useProd = true;

const testEmailParams = {
  service_id: "service_vcbm8zd",
  template_id: "template_jzux0jx",
  public_key: "FQsZkZgwIUTWSnDnc",
};

const prodEmailParams = {
  service_id: "service_00nadic",
  template_id: "template_bd4zchg",
  public_key: "Lj5NKSZyJg7aFG1vT",
};

const ContactForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const name = e.target.name.value;
    const message = e.target.message.value;

    const templateParams = {
      from_name: name,
      reply_to: email,
      message: message,
    };
    setIsLoading(true);
    const result = await emailjs.send(
      useProd ? prodEmailParams.service_id : testEmailParams.service_id,
      useProd ? prodEmailParams.template_id : testEmailParams.template_id,
      templateParams,
      useProd ? prodEmailParams.public_key : testEmailParams.public_key
    );

    if (result.status === 200) {
      toast.success("Message sent successfully");
    } else {
      toast.error("Message failed to send");
    }
    formRef.current.reset();
    setIsLoading(false);
  };

  return (
    <section {...props} className={outerClasses} id="contact-us">
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Contact Us</h3>
          </div>
          <div className="cta-action">
            <form onSubmit={handleSubmit} ref={formRef}>
              <Input
                id="email"
                type="email"
                label="Email"
                placeholder="Your email"
                required
              />
              <Input
                id="name"
                type="text"
                label="Name"
                placeholder="Your name"
                required
              />
              <Input
                id="message"
                type="textarea"
                label="Message"
                placeholder="Your message"
                rows={5}
                required
              />
              <Button className="mt-16" type="submit" loading={isLoading}>
                Send Message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
