import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { BlackOverlay, FloatingText, FloatingTextContainer } from "./style";
import { useMediaQuery } from "../../../hooks/MediaHook";

const fadeImages = [
  {
    url: "https://mtntownmagazine.com/wp-content/uploads/2018/03/REAL-ESTATE-architect-house-plans-rebucolor-for-architectural-designs-drawings-architecture-design-jobs-awards-the-part-of-and.jpg",
    text: "Design Services",
  },
  {
    url: require("../../../assets/images/caseStudies/Orchard_House.jpg"),
    text: "Orchard House - Derry",
  },
  {
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Central_Foundation_Boys%27_School_2.jpg/1024px-Central_Foundation_Boys%27_School_2.jpg",
    text: "Central Foundation Boys School - London",
  },
  {
    url: "https://pagabo.co.uk/storage/uploads/lambeth-2.jpg",
    text: "Lambeth Steam College - London",
  },
  {
    url: "https://www.bathecho.co.uk/uploads/2022/02/pixash-recycling-site-banes-plans.jpg",
    text: "Pixash Recycling Centre - Bath",
  },
];

export const FadingImageCarousel = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <div
        className="slide-container"
        style={{
          zIndex: -10,
        }}
      >
        <Fade arrows={false} duration={3000}>
          {fadeImages.map((fadeImage, index) => (
            <>
              <div key={index}>
                <img
                  style={{ width: "100vw", height: "100vh" }}
                  src={fadeImage.url}
                  alt=""
                />
              </div>
              <BlackOverlay opacity={isMobile ? 0.7 : 0.8} />
              {!isMobile && (
                <FloatingTextContainer>
                  <FloatingText>{fadeImage.text}</FloatingText>
                </FloatingTextContainer>
              )}
            </>
          ))}
        </Fade>
      </div>
    </>
  );
};
